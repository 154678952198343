@import url('https://fonts.googleapis.com/css?family=Montserrat');

:root {
  --primaryColor: rgb(0,100,0);
  --secondaryColor: rgb(172,209,175);
  --mainGrey: #ededed;
  --darkGrey: #5f5e5e;
  --mainWhite: #fff;
  --mainBlack: #222;
}

body {
    margin: 0;
    padding: 0;
    font-size: 100%;
    line-height: 1.5;
    color: #555;
    font-family: 'Montserrat', sans-serif;
}
article, aside, figcaption, figure, footer, header, nav, section {
    display: block;
}
h1, h2, h3, h4, stong {
    margin-top: 1em;
    padding-top: 1em;
    line-height: 1.25;
    color: #333;
}
h1 {
    font-size: 2.5rem;
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.5rem;
}
h4 {
    font-size: 1rem;
}
ul, ol {
    margin: 1em 0;
    padding-left: 40px;
}
p, figure {
    margin: 1em 0;
}
a img {
    border: none;
}
sup, sub {
    line-height: 0;
}