#footer {
  background-color: var(--mainBlack);
  font-weight: bolder;
  padding: 10px;
  text-align: center;
  color: var(--mainWhite);
  .text-title {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: var(--mainWhite);
    &:hover {
      color: var(--secondaryColor);
    }
  }
}