#about {
  text-align: center;
  width: 100vw;
  background-color: var(--mainGrey);

  .heading {
    font-size: 20px;
    position: relative !important;
    h1 {
      margin: 0;
      padding: 20px 0;
    }
    img {
      display: grid;
      grid-gap: 30px;
      padding-left: 20px;
      width: 250px;
      height: 250px;
      border-radius: 50%;
      margin: auto;
      @media (max-width: 800px) {
        padding-left: 0;
      }
    }
    a {
      text-decoration: none;
      color: #484888;
      &:hover {
          color: #5b5ba8;
      }
    }
    p {
      padding: 0 50px;
      line-height: 30px;
      @media (max-width: 414px) {
        font-size: 15px;
      }
    }
  }
  .skills-icons {
    text-align: center;
    .icon {
      font-size: 50px;
      padding: 20px 30px;
      &:hover {
        transform: scale(1.2);
      }
    }
    .tooltip {
      visibility: hidden;
    }
  }
  .description {
    display: flex;
    @media (max-width: 800px) {
      display: block;
    }
  }
}
